import React, { useEffect, useState } from "react";
import {
  Checkbox,
  TextField,
  Select,
  TooltipProps,
  tooltipClasses,
  Tooltip,
  MenuItem,
  ListItemText,
  InputLabel,
  FormControl,
} from "@mui/material";
import { isArrayEmpty } from "helpers/helpers";
import InfoIcon from "@mui/icons-material/Info";
import { styled as styledMui } from "@mui/material/styles";
import { LanguageEnum } from "helpers/constants";
import TooltipActivityLanguages from "./TooltipActivityLanguages";

const HtmlTooltip = styledMui(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "1rem",
    padding: "1rem",
  },
}));

const SelectActivityLanguages = (props: {
  disabled?: boolean;
  multiple: boolean;
  placeholder?: string;
  onChange: any;
  values: string[];
  id: string;
  name: string;
  required?: boolean;
  error: any;
  onChangeOther: Function;
  otherLanguages: string[];
  t: any;
}) => {
  const {
    multiple,
    disabled,
    values,
    placeholder,
    onChange,
    id,
    name,
    required,
    error,
    onChangeOther,
    otherLanguages,
    t,
  } = props;
  const [otherLanguage, setOtherLanguage] = useState("");
  const [open, setOpen] = useState(false);
  const [other, setOther] = useState(false);

  useEffect(() => {
    if (!isArrayEmpty(otherLanguages)) {
      setOther(true);
      setOtherLanguage(otherLanguages.join(", "));
    } else {
      setOther(false);
      setOtherLanguage("");
    }
  }, [otherLanguages]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>): void => {
    event.target.value = (event.target.value as string[])?.filter(Boolean);
    onChange(event);
  };

  const toggleOther = () => {
    if (other) {
      setOtherLanguage("");
      onChangeOther([]);
    }
    setOther(!other);
  };

  return (
    <FormControl>
      <InputLabel shrink>{placeholder}</InputLabel>
      <Select
        name={name}
        id={id}
        multiple={multiple}
        displayEmpty
        disabled={disabled}
        label={placeholder}
        value={values}
        required={required}
        variant="outlined"
        error={error}
        renderValue={(selected) => {
          if (isArrayEmpty(selected) && otherLanguage === "") {
            return <></>;
          } else {
            return (
              <span style={{ maxWidth: "400px" }}>
                {[...values.map((value) => t(value)), ...otherLanguages].join(
                  ", ",
                )}
              </span>
            );
          }
        }}
        onChange={handleChange as any}
        open={open}
        onClose={handleClose}
        onOpen={handleOpen}
      >
        {Object.values(LanguageEnum).map((lang) => (
          <MenuItem key={lang} value={lang}>
            <Checkbox checked={values.includes(lang)} />
            <ListItemText primary={t(lang)} sx={{ fontSize: "14px" }} />
          </MenuItem>
        ))}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            alignItems: "center",
            padding: "6px 16px",
          }}
        >
          <Checkbox checked={other} onChange={toggleOther} />
          <ListItemText primary={t("other")} sx={{ fontSize: "14px" }} />
          {other && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 5,
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextField
                size="small"
                placeholder={t("enterLanguages")}
                value={otherLanguage}
                onChange={(e: any) => setOtherLanguage(e.target.value)}
                onBlur={() =>
                  onChangeOther(
                    otherLanguage.split(",").map((lang) => lang.trim()),
                  )
                }
              />
              <HtmlTooltip title={<TooltipActivityLanguages />}>
                <InfoIcon color="primary" />
              </HtmlTooltip>
            </div>
          )}
        </div>
      </Select>
    </FormControl>
  );
};

export default SelectActivityLanguages;
