import { useState, useRef, useEffect, useCallback } from "react";
import { TextField, IconButton, Box } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";

interface CommentBoxProps {
  id?: number;
  value?: string;
  onPost: Function;
}

const CommentBox = (props: CommentBoxProps) => {
  const { id, value, onPost } = props;
  const [comment, setComment] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (value) {
      setComment(value);
    }
  }, [value]);

  const handleOk = useCallback(
    (type: "add" | "delete" | "edit") => {
      if (type === "delete" && !id) {
        setComment("");
      } else {
        onPost([{ id, comment, action: type }]);
        if (type === "add") {
          setComment("");
        }
      }
      setIsFocused(false);
    },
    [id, comment, onPost, setIsFocused, setComment]
  );

  return (
    <Box
      ref={containerRef}
      tabIndex={-1}
      onFocus={() => setIsFocused(true)}
      onBlur={(e) => {
        if (!containerRef.current?.contains(e.relatedTarget)) {
          setIsFocused(false);
        }
      }}
      sx={{
        display: "flex",
        alignItems: "center",
        border: "1px solid #ddd",
        borderRadius: "8px",
        padding: "8px",
        width: "100%",
        position: "relative"
      }}
    >
      <TextField
        fullWidth
        multiline
        hiddenLabel
        variant="standard"
        placeholder="Ecrivez un commentaire..."
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        maxRows={10}
        InputProps={{ disableUnderline: true }}
        sx={{ margin: 0, padding: "0px 30px 0px 10px" }}
      />
      {isFocused && (
        <div style={{ position: "absolute", right: 10, display: "flex", flexDirection: "column", gap: 5 }}>
          <IconButton onClick={() => handleOk(!!id ? "edit" : "add")} sx={{ padding: 0, backgroundColor: "#2CA58D", color: "#fff", borderRadius: "6px" }}>
            <CheckIcon fontSize="small" />
          </IconButton>
          <IconButton onClick={() => handleOk("delete")} sx={{ padding: 0, backgroundColor: "#DD0000", color: "#fff", borderRadius: "6px" }}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
      )}
    </Box>
  );
};

export default CommentBox;
