import { useEffect, useState } from "react";
import {
  ActivityPricesTypeEnum,
  BookingStatusEnum,
  PlatformTypeEnum,
} from "../../../helpers/constants";
import { Booking } from "../types";

import CheckCircleIcon from "@mui/icons-material/CheckCircle";
// import ReportIcon from '@mui/icons-material/Report';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
import MonetizationOn from "@mui/icons-material/MonetizationOn";
import InfoIcon from "@mui/icons-material/Info";

import CancelIcon from "@mui/icons-material/Cancel";
import {
  statusColorMapping,
  isBookingCanBeUpdated,
  isBookingStatusCanBeUpdated,
} from "./utis";
import styled from "styled-components";
import { isArrayEmpty } from "helpers/helpers";
import BookingExtraInfosModal from "./bookingExtraInfosModal/BookingExtraInfosModal";
import { SelectStaff } from "./SelectStaff";
import { User } from "types/auth";
import { Options } from "components/formInputs/inputs.types";

export default function BookingCard(props: {
  booking: Booking;
  users: User[];
  setSelectedBookingForUpdate: any;
  setSelectedBookingForCancel: any;
  setSelectedBookingForConfirmation: any;
  setSelectedBookingForPaid: any;
  updateBookinUsersIds: any;
  t: any;
}) {
  const {
    booking,
    users,
    setSelectedBookingForCancel,
    setSelectedBookingForConfirmation,
    setSelectedBookingForPaid,
    updateBookinUsersIds,
    t,
  } = props;
  const color = booking.status === BookingStatusEnum.DONE ? "white" : "inherit";
  const backgroundColor = statusColorMapping[booking.status];
  const [hidden, setHidden] = useState(true);
  const [selectedStaff, setSelectedStaff] = useState<number[]>([]);
  const [openBookingExtraInfosModal, setOpenBookingExtraInfosModal] =
    useState(false);

  useEffect(() => {
    setSelectedStaff(booking.usersIds);
  }, [booking]);

  const handleOk = (userIds: number[]) => {
    setSelectedStaff(userIds)
    updateBookinUsersIds(booking.bookingId, userIds)
  }

  return (
    <BookingCardContainer
      style={{ color, backgroundColor }}
      onMouseEnter={() => setHidden(false)}
      onMouseLeave={() => setHidden(true)}
    >
      <BookingExtraInfosModal
        openBookingExtraInfosModal={openBookingExtraInfosModal}
        setOpenBookingExtraInfosModal={setOpenBookingExtraInfosModal}
        extraInfos={booking.extraInfos}
        t={t}
      />
      <BookingCardContent users={users} booking={booking} t={t} selectedStaff={selectedStaff} handleOk={handleOk} />
      {!hidden ? (
        <BookingCardHiddenButtons>
          {!isArrayEmpty(booking.extraInfos) && (
            <InfoIcon
              style={{ color: "#f29d38" }}
              onClick={() => setOpenBookingExtraInfosModal(true)}
            />
          )}

          {isBookingStatusCanBeUpdated(booking.status) ? (
            <>
              <CheckCircleIcon
                style={{ color: "#4FD1C5" }}
                onClick={() => setSelectedBookingForConfirmation(booking)}
              />
            </>
          ) : null}
          {isBookingCanBeUpdated(booking.status) ? (
            <>
              {/* #TODO: reactivate when we will authorize booking updates */}
              {/* <BorderColorIcon style={{ color: "#004643" }} onClick={() => setSelectedBookingForUpdate(booking)} /> */}
              {booking.platformType === PlatformTypeEnum.HOPLEISURE ? (
                <MonetizationOn
                  style={{ color: "#004643" }}
                  onClick={() => setSelectedBookingForPaid(booking)}
                />
              ) : null}
              <CancelIcon
                style={{ color: "red" }}
                onClick={() => setSelectedBookingForCancel(booking)}
              />
            </>
          ) : null}
        </BookingCardHiddenButtons>
      ) : null}
    </BookingCardContainer>
  );
}

export function BookingCardContent(props: { booking: Booking; users: User[]; t: any; selectedStaff?: number[]; handleOk?: any }) {
  const { booking, t, selectedStaff, handleOk, users } = props;
  const ticketsBookedRecap = booking.tickets
    .map(
      (ticket) =>
        `${ticket.name === ActivityPricesTypeEnum.GROUP ? 1 : ticket.numberOfUnit} ${ticket.name}`,
    )
    .join(",");

  const staffOption: Options = [
    ...(users.map((user) => ({
      value: user.id,
      label: user.fullName,
      avatar: user.profilePictureUrl
    })))
  ];

  return (
    <>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div>{booking.fullName}</div>
        <div>{booking.email}</div>
        <div>{booking.phoneNumber}</div>
      </div>
      <div style={{ width: "40%", display: "flex", flexDirection: "column" }}>
        <div>{`${booking.numberOfUnitOnBooking} ${t("people(s)")} ${booking.status}`}</div>
        <div>{ticketsBookedRecap}</div>
        <div>
          {`
                ${booking.totalPaid.toFixed(2)} € 
                ${booking.discount ? `- ${booking.discount}` : ""} 
                | ${booking.totalPaid !== booking.totalPaidWithDiscount ? booking.totalPaidWithDiscount.toFixed(2) + " €" : ""} 
                ${booking.paid
              ? booking.platformType !== PlatformTypeEnum.HOPLEISURE
                ? `${t("payedBy")}: ${booking.platformName}`
                : t("paid")
              : t("notPaid")
            }
              `}
        </div>
        <div>{booking.externalReference ?? "-"}</div>
        {booking.status === BookingStatusEnum.CANCELED && booking.paid ? (
          <div>
            {`
                  ${booking.platformType === PlatformTypeEnum.HOPLEISURE ? t("toRefundHopleisure") : ""} 
                  ${booking.platformType === PlatformTypeEnum.WIDGET ? t("toRefundWidget", { orderId: booking.orderId }) : ""} 
                  ${booking.platformType !== PlatformTypeEnum.HOPLEISURE && booking.platformType !== PlatformTypeEnum.WIDGET ? t("toRefundPlatform", { platformName: booking.platformName }) : ""} 
                `}
          </div>
        ) : null}
      </div>
      {selectedStaff && (
        <div style={{width: "25%"}}>
          <SelectStaff
            multiple
            options={staffOption}
            handleOk={handleOk}
            values={selectedStaff ?? []}
            id={"selectStaff"}
            name={"selectStaff"}
          />
        </div>
      )}
    </>
  );
}

const BookingCardContainer = styled.div`
  width: 100%;
  position: relative;
  padding: 10px 0px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: space-around;
`;

const BookingCardHiddenButtons = styled.div`
  cursor: pointer;
  position: absolute;
  right: -10px;
  display: flex;
  flex-direction: column;
  background-color: white;
`;
