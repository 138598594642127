import React, { useEffect, useState } from "react";
import { Button, Avatar, AvatarGroup, MenuItem, Select, Stack, Checkbox, IconButton } from "@mui/material";
import { isArrayEmpty } from "helpers/helpers";
import { useTranslation } from "react-i18next";
import { Options } from "components/formInputs/inputs.types";
import { FormBox } from "helpers/generalStyles";
import { Add } from "@mui/icons-material";
import ChangeUserIcon from "images/edit.svg";

interface SelectStaffProps {
  disabled?: boolean;
  multiple: boolean;
  options: Options;
  handleOk: (value: number[]) => void;
  values: number[];
  id: string;
  name: string;
  required?: boolean;
}

export function SelectStaff(props: SelectStaffProps) {
  const {
    multiple,
    disabled,
    values,
    options,
    handleOk,
    id,
    name,
    required,
  } = props;
  const [open, setOpen] = useState(false);
  const [selectedStaff, setSelectedStaff] = useState<number[]>([]);
  const { t } = useTranslation("agenda");

  useEffect(() => {
    setSelectedStaff(values)
  }, [values])

  const handleClose = () => {
    setOpen(false);
    setSelectedStaff(values);
  };
  const handleOpen = () => setOpen(true);
  const handleChange = (event: any) => {
    event.target.value = (event.target.value as number[] || []).filter(Boolean);
    setSelectedStaff(event.target.value)
  };

  const onClickOk = () => {
    setOpen(false);
    handleOk(selectedStaff);
  }

  return (
    <FormBox style={{ alignItems: "flex-end" }}>
      <Select
        name={name}
        id={id}
        multiple={multiple}
        displayEmpty
        disabled={disabled}
        value={selectedStaff}
        required={required}
        renderValue={(selected) => (
          isArrayEmpty(selected) ? (
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex", cursor: "pointer", backgroundColor: "#bdbdbd", borderRadius: 1000, width: 45, height: 45, justifyContent: "center", alignItems: "center" }}>
                <Add fontSize="small" />
              </div>
            </div>
          ) : (
            <Stack direction="row" alignItems="center" position="relative" spacing={1}>
              <AvatarGroup max={3}>
                {(selected as number[]).map((id) => {
                  const user = options.find((opt) => Number(opt.value) === id);
                  return user ? <Avatar key={id} src={user.avatar} alt={user.label} /> : null;
                })}
              </AvatarGroup>
              <IconButton
                aria-label="edit"
                color="primary"
                sx={{ position: "absolute", right: -10, bottom: -10 }}
              >
                <img
                  src={ChangeUserIcon}
                  alt="change user"
                  style={{
                    width: "20px",
                    height: "15px",
                  }}
                />
              </IconButton>
            </Stack>
          )
        )}
        onChange={handleChange}
        open={open}
        onClose={handleClose}
        IconComponent={() => null}
        onOpen={handleOpen}
        sx={{
          boxShadow: "none",
          ".MuiOutlinedInput-notchedOutline": { border: 0 },
          "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
          "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
          {
            border: 0,
          },
        }}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value} sx={{ display: "flex", justifyContent: "space-between" }}>
            <div style={{ display: "flex", flexDirection: "row" }}>
              <Avatar src={option.avatar} alt={option.label} sx={{ width: 30, height: 30, marginRight: 1 }} />
              {option.label}
            </div>
            <Checkbox
              checked={selectedStaff.indexOf(Number(option.value)) > -1}
              sx={{ paddingBlock: "0" }}
            />
          </MenuItem>
        ))}
        <div
          style={{
            padding: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%"
          }}
        >
          <Button variant="contained" color="info" onClick={onClickOk}>
            {t("ok")}
          </Button>
        </div>
      </Select>
    </FormBox>
  );
}
