import { Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";

export default function TooltipActivityLanguages() {
  const { t } = useTranslation("activities");
  return (
    <div>
      <Typography fontWeight={700} fontSize={14}>
        {t("tooltipActivityLanguages")}
      </Typography>
      <Typography color="#054643" fontSize={12} fontWeight={700}>
        {t("languagesExample")}
      </Typography>
    </div>
  );
}
