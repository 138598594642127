import { ActivityHoursType, Slot } from "backend/api/activities";
import moment from "moment";
import { DayListType, DayType } from "./HoursForm.types";
import { DATEFORMAT } from "helpers/constants";
import { isArrayEmpty } from "helpers/helpers";

export const checkDayDisabled = (
  selectedDaysForDuplication: string[],
  dayToCheck: string,
) => {
  return selectedDaysForDuplication.every((day) => day !== dayToCheck);
};

export const getSelectorDaysOptions = (
  mapping: Record<string, string>,
  selectedDaysForDuplication: string[],
): { value: string; label: string; disabled: boolean }[] => {
  return Object.entries(mapping).map(([day, label]) => ({
    value: day,
    label: label,
    disabled: checkDayDisabled(selectedDaysForDuplication, day),
  }));
};

export const getHoursSingleTimeslot = (
  hours: ActivityHoursType,
): Slot[] | [] => {
  let daysWithSlots = 0;
  let validOpeningHours = [];

  for (const day in hours) {
    const { slots } = hours[day as keyof ActivityHoursType];
    if (!isArrayEmpty(slots)) {
      daysWithSlots++;
      if (daysWithSlots > 1) {
        return [];
      }
      for (const slot of slots) {
        const { from, to } = slot;
        const isFromDateBeforeToDate = moment(from, DATEFORMAT.TIME).isBefore(
          moment(to, DATEFORMAT.TIME),
        );
        if (isFromDateBeforeToDate) {
          validOpeningHours.push(slot);
        }
      }
    }
  }

  return validOpeningHours;
};

export const duplicateHours = (
  hours: ActivityHoursType,
  selectedDaysForDuplication: string[],
  validOpeningHours: Slot[],
) => {
  const duplicatedHours: ActivityHoursType = Object.keys(hours).reduce(
    (acc: any, key: string) => {
      if (
        selectedDaysForDuplication.some(
          (selectedDayForDuplication) => selectedDayForDuplication === key,
        )
      ) {
        acc[key] = { slots: validOpeningHours };
      } else acc[key] = { slots: [] };
      return acc;
    },
    {},
  );
  return duplicatedHours;
};

export const getDaysInRange = (
  startDate: string,
  endDate: string,
): DayListType => {
  const days: DayListType = [];
  let currentDate = moment(startDate);

  while (currentDate.isSameOrBefore(endDate, "day")) {
    const dayOfWeek = currentDate.format("d") as DayType;
    days.push(dayOfWeek);
    currentDate.add(1, "day");
  }

  return days;
};

export const isDayEnabled = (enabledDays: string[], selectedDay: string) => {
  return enabledDays.some((day) => selectedDay === day);
};

export const filterHoursByEnabledDays = (
  enabledDays: string[],
  hours: ActivityHoursType,
) => {
  return Object.keys(hours).reduce((filteredHours: any, day: string) => {
    const dayEnabled = isDayEnabled(enabledDays, day);
    if (dayEnabled) {
      filteredHours[day as keyof ActivityHoursType] =
        hours[day as keyof ActivityHoursType];
    } else filteredHours[day] = { slots: [] };
    return filteredHours;
  }, {});
};
