import { TOGGLE_LANGUAGE } from "constants/toggleConstants";

const initialState = {
  language: "fr",
};

export const toggleReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case TOGGLE_LANGUAGE:
      return {
        ...state,
        language: action.payload,
      };
    default:
      return state;
  }
};

export const toggleLanguage = (payload: string) => ({
  type: TOGGLE_LANGUAGE,
  payload
});
