import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  ActivityValidationSlotsPayload,
  postActivityValidationSlots,
} from "backend/api/activities";
import { ActivitySlotStatus } from "helpers/constants";
import { toastEmitter } from "helpers/helpers";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useMutation } from "react-query";

interface IDialogPunctualSlot {
  activityId: number;
  open: boolean;
  setOpen: (open: boolean) => void;
  dataOverlap: any;
  saveActivity: VoidFunction;
}

export default function DialogPunctualSlot({
  activityId,
  open,
  setOpen,
  dataOverlap,
  saveActivity,
}: IDialogPunctualSlot) {
  const { t } = useTranslation("activities");
  const { mutate } = useMutation({
    mutationFn: postActivityValidationSlots,
  });

  const handleClose = useCallback(() => {
    setOpen(false);
  }, [setOpen]);

  const handleSubmit = async (slot: ActivitySlotStatus) => {
    const payload: ActivityValidationSlotsPayload = {
      activityId,
      data: dataOverlap?.data?.map((over: any) => ({
        ...over,
        slot,
      })),
      notOverlap: dataOverlap?.notOverlap,
    };
    mutate(payload, {
      onSuccess: () => {
        saveActivity();
        handleClose();
      },
      onError: (error: any) => {
        toastEmitter(error?.response?.data?.error, "error");
      },
    });
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle
        sx={{
          fontWeight: "600",
          letterSpacing: "1px",
          color: "#2CA58D",
          textAlign: "center",
          paddingBottom: 0,
        }}
      >
        {t("attention")}
      </DialogTitle>
      <DialogContent
        sx={{
          whiteSpace: "pre-line",
          paddingBottom: 0,
        }}
      >
        {t("punctualDialogDescription")}
      </DialogContent>
      <DialogActions style={{ paddingInline: "1rem", paddingBottom: "1rem" }}>
        <Button variant="contained" color="error" onClick={handleClose}>
          {t("no")}
        </Button>
        <Button
          variant="contained"
          color="success"
          onClick={() => handleSubmit(ActivitySlotStatus.CANCEL)}
        >
          {t("yes")}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
