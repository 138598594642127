import { zodResolver } from "@hookform/resolvers/zod";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import {
  Tab,
  Button,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch,
} from "react-hook-form";
import {
  Checkbox,
  InputCheckboxSelect,
  InputText,
  Select,
} from "../../../formInputs/Inputs";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Column,
  FormDiv,
  FormLabel,
  Row,
  SecondaryTitleText,
} from "../../../../helpers/generalStyles";
import Locker from "../../Locker";
import {
  activityHours,
  ActivityHours,
  Slot,
  upsertActivity as apiUpsertActivity,
  postCheckOverlappingSlots,
  CheckOverlappingSlots,
} from "../../../../backend/api/activities";
import { useMutation } from "react-query";
import { queryClient } from "../../../..";
import AlertMessage from "../../../general/AlertMessage";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { ACTIVITY_SAVE_STATE_UPDATE } from "constants/activityConstants";
import {
  arrayToFormattedString,
  isArrayEmpty,
  Message,
  toastEmitter,
} from "../../../../helpers/helpers";
import styled from "styled-components";
import { styled as styledMui } from "@mui/material/styles";
import { useScheduleValidation } from "hooks/useScheduleValidation";
import DialogConfirmation from "components/general/DialogConfirmation";
import { Container } from "../../style";
import InfoIcon from "@mui/icons-material/Info";
import TooltipHour from "../../tooltipHour/TooltipHour";
import {
  activityScheduleTypes,
  ActivityTypeHours,
  DATEFORMAT,
  SlotPunctual,
} from "helpers/constants";
import { handleSelectChange } from "components/tabs/widget/WidgetTab.utils";
import {
  duplicateHours,
  getHoursSingleTimeslot,
  getDaysInRange,
  getSelectorDaysOptions,
  isDayEnabled,
  filterHoursByEnabledDays,
} from "./HoursForm.utils";
import moment from "moment";
import "./HoursForm.style.css";
import {
  dayList,
  daysNameMapping,
  duplicateOpeningHoursBtnStyle,
  initialHoursObject,
  minimalTimeslotValue,
  MODAL_CHECK_OVERLAPPING,
  MODAL_WARNING_PUNCTUAL,
} from "./HoursForm.constants";
import DialogPunctualSlot from "components/activities/dialog/DialogPunctualSlot";
import { isEqual } from "lodash";

const HtmlTooltip = styledMui(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 500,
    fontSize: theme.typography.pxToRem(12),
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: "1rem",
    padding: "1rem",
  },
}));

export function HoursForm(props: {
  inputData: ActivityHours;
  activityId: number | null;
  isLockerOpen: boolean;
  setIsLockerOpen: any;
  navigate: any;
  t: any;
}) {
  const { inputData, activityId, isLockerOpen, setIsLockerOpen, t } = {
    ...props,
  };
  const { validateSchedule } = useScheduleValidation();
  const [page, setPage] = useState("1");
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenModalPunctual, setIsOpenModalPunctual] = useState({
    open: "",
    dataOverlap: {},
  });
  const [alertMessage, setAlertMessage] = useState<Message>({
    type: "success",
    message: "",
  });
  const [selectedDaysForDuplication, setSelectedDaysForDuplication] =
    useState(dayList);
  const [enabledDaysForDuplication, setEnabledDaysForDuplication] =
    useState(dayList);
  const [showDuplicationDaysSelector, setShowDuplicationDaysSelector] =
    useState(false);
  const [
    isTemporayActivityDatesRangeValid,
    setIsTemporayActivityDatesRangeValid,
  ] = useState(false);
  const [isTimeslotInfoModalOpen, setIsTimeslotInfoModalOpen] = useState(false);
  const [offerOpeningHoursDuplication, setOfferOpeningHoursDuplication] =
    useState(false);
  const dispatch = useDispatch();
  const [validOpeningHours, setValidOpeningHours] = useState<Slot[] | []>([]);
  const { saveActivityInProgress } = useSelector(
    (state: RootState) => state.activity,
  );

  const activityScheduleType = Object.entries(activityScheduleTypes).map(
    (obj) => {
      return { value: obj[0], label: t(obj[1]) };
    },
  );

  useEffect(() => {
    setAlertMessage({ message: "", type: "success" });
  }, [inputData]);

  const mutateActivity = useMutation({
    mutationFn: (data: ActivityHours) =>
      apiUpsertActivity({
        hours: data,
        activityId: activityId ? String(activityId) : null,
      }),
    onSuccess: (result: any) => {
      queryClient.invalidateQueries({
        queryKey: ["getActivitiesWithDisabled"],
      });
      toastEmitter(t("saveActivityHoursSuccessfully"), "success");
      dispatch({ type: ACTIVITY_SAVE_STATE_UPDATE, hours: true });
      setOfferOpeningHoursDuplication(false);
      setShowDuplicationDaysSelector(false);
      setValidOpeningHours([]);
      setIsLockerOpen(false);
    },
    onError: (error: any) => {
      setAlertMessage({
        type: "error",
        message: t(error?.response?.data?.error, {
          day: arrayToFormattedString(
            errorValidation.errorsNotValidSlot,
            t("and"),
          ),
        }),
      });
    },
  });

  const mutateCheckOverlapping = useMutation({
    mutationFn: (data: { activityId: number; data: CheckOverlappingSlots }) =>
      postCheckOverlappingSlots(data.activityId, data.data),
    onSuccess: () => {
      mutateActivity.mutate(payload());
    },
    onError: (error: any) => {
      if (
        error?.response?.data?.error === SlotPunctual.OVERLAPPING_SLOTS_CREATION
      ) {
        setIsOpenModalPunctual({
          open: MODAL_CHECK_OVERLAPPING,
          dataOverlap: error?.response?.data,
        });
      } else {
        setAlertMessage({
          type: "error",
          message: t(error?.response?.data?.error, {
            day: arrayToFormattedString(
              errorValidation.errorsNotValidSlot,
              t("and"),
            ),
          }),
        });
      }
    },
  });

  const normalizedInputData = useMemo(() => {
    const slotPunctual =
      inputData.slotPunctual?.map((slot) => ({
        id: slot.id,
        date: slot.date,
        slotFrom: slot.slotFrom,
        slotTo: slot.slotTo,
        flag: "",
      })) ?? [];
    return {
      ...inputData,
      typeHours: inputData.typeHours ?? "",
      hours: inputData.hours ?? initialHoursObject,
      slotDuration: inputData.slotDuration ?? minimalTimeslotValue,
      hasSpecialDates: Boolean(
        inputData?.dates && inputData.dates.from && inputData.dates.to,
      ),
      slotPunctual,
    };
  }, [inputData]);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    getValues,
    setValue,
    reset,
    register,
  } = useForm({
    resolver: zodResolver(activityHours),
    values: normalizedInputData,
  });

  //useWatch enable detection of changes for deep nested object
  const [hours, dates, hasSpecialDates] = useWatch({
    control,
    name: ["hours", "dates", "hasSpecialDates"],
  });

  useEffect(() => {
    if (hasSpecialDates) {
      const startDate = dates?.from;
      const endDate = dates?.to;
      if (startDate && endDate) {
        const isDateRangeValid = moment(endDate, DATEFORMAT.DAY).isAfter(
          moment(startDate, DATEFORMAT.DAY),
        );
        setIsTemporayActivityDatesRangeValid(isDateRangeValid);
        if (isDateRangeValid) {
          const daysInRange = getDaysInRange(startDate, endDate);
          const filteredHoursByEnabledDays = filterHoursByEnabledDays(
            daysInRange,
            hours,
          );
          setValue("hours", filteredHoursByEnabledDays);
          reset(getValues());
          setEnabledDaysForDuplication(daysInRange);
          setSelectedDaysForDuplication(daysInRange);
        }
      } else setIsTemporayActivityDatesRangeValid(false);
    } else {
      setEnabledDaysForDuplication(dayList);
      setSelectedDaysForDuplication(dayList);
    }
  }, [dates?.from, dates?.to, hasSpecialDates, setValue]);

  useEffect(() => {
    if (!showDuplicationDaysSelector)
      setValidOpeningHours(getHoursSingleTimeslot(hours));
  }, [hours, showDuplicationDaysSelector]);

  useEffect(() => {
    setOfferOpeningHoursDuplication(
      Boolean(isLockerOpen) && !isArrayEmpty(validOpeningHours),
    );
  }, [validOpeningHours, isLockerOpen]);

  useEffect(() => {
    if (
      !isArrayEmpty(validOpeningHours) &&
      !isArrayEmpty(selectedDaysForDuplication) &&
      showDuplicationDaysSelector
    ) {
      const duplicatedHours = duplicateHours(
        hours,
        selectedDaysForDuplication,
        validOpeningHours,
      );
      setValue("hours", duplicatedHours);
      reset(getValues());
    }
  }, [
    hours,
    selectedDaysForDuplication,
    showDuplicationDaysSelector,
    validOpeningHours,
  ]);

  const saveDuplicatedOpeningHours = () => {
    if (isArrayEmpty(selectedDaysForDuplication)) {
      toastEmitter(t("duplicationErrorMsg"), "error");
    } else {
      onSubmit(getValues());
    }
  };

  //reset form to correct isDirty state used to save the form if quitting the page
  useEffect(() => {
    if (isLockerOpen) {
      reset(normalizedInputData);
    }
  }, [isLockerOpen, normalizedInputData]);

  const values = getValues();
  const isDirty = useMemo(
    () => !isEqual(normalizedInputData, values),
    [normalizedInputData, values],
  );

  const errorValidation = useMemo(() => {
    return validateSchedule(watch());
  }, [validateSchedule, watch]);

  const payload = useCallback(() => {
    return {
      ...watch(),
      slotPunctual: watch().slotPunctual?.map((slot: any) => ({
        ...slot,
        id: slot.flag !== SlotPunctual.ADD ? slot.id : undefined,
        flag: slot.flag || undefined,
      })),
    };
  }, [watch()]);

  const onSubmit: SubmitHandler<any> = async (data: ActivityHours) => {
    if (errorValidation.errorsEmptySlot.length > 0) {
      return setIsOpenModal(true);
    }

    if (hasSpecialDates && !isTemporayActivityDatesRangeValid) {
      return toastEmitter(t("temporaryActivityDatesError"), "error");
    }
    if (
      values.typeHours === ActivityTypeHours.TIME_SLOTS &&
      !isTimeslotInfoModalOpen
    ) {
      return setIsTimeslotInfoModalOpen(true);
    }
    if (data.slotPunctual?.length && activityId) {
      mutateCheckOverlapping.mutate({
        activityId,
        data: { hours: payload() },
      });
      return false;
    }

    mutateActivity.mutate(payload());
  };

  useEffect(() => {
    if (isLockerOpen) {
      dispatch({
        type: ACTIVITY_SAVE_STATE_UPDATE,
        payload: { hours: !isDirty },
      });
      if (saveActivityInProgress && isDirty) {
        const formData = getValues();
        onSubmit(formData);
      }
    } else {
      dispatch({
        type: ACTIVITY_SAVE_STATE_UPDATE,
        payload: { hours: true },
      });
    }
  }, [saveActivityInProgress, isDirty]);

  const {
    fields: mondayFields,
    append: mondayAppend,
    remove: mondayRemove,
  } = useFieldArray({ control, name: "hours.1.slots" });
  const {
    fields: tuesdayFields,
    append: tuesdayAppend,
    remove: tuesdayRemove,
  } = useFieldArray({ control, name: "hours.2.slots" });
  const {
    fields: wednesdayFields,
    append: wednesdayAppend,
    remove: wednesdayRemove,
  } = useFieldArray({ control, name: "hours.3.slots" });
  const {
    fields: thursdayFields,
    append: thursdayAppend,
    remove: thursdayRemove,
  } = useFieldArray({ control, name: "hours.4.slots" });
  const {
    fields: fridayFields,
    append: fridayAppend,
    remove: fridayRemove,
  } = useFieldArray({ control, name: "hours.5.slots" });
  const {
    fields: saturdayFields,
    append: saturdayAppend,
    remove: saturdayRemove,
  } = useFieldArray({ control, name: "hours.6.slots" });
  const {
    fields: sundayFields,
    append: sundayAppend,
    remove: sundayRemove,
  } = useFieldArray({ control, name: "hours.0.slots" });
  const {
    fields: slotPunctualFields,
    append: slotPunctualAppend,
    remove: slotPunctualRemove,
  } = useFieldArray({ control, name: "slotPunctual" });

  const slotIterator = (dayNumber: string) => {
    if (dayNumber === "1") return mondayFields;
    if (dayNumber === "2") return tuesdayFields;
    if (dayNumber === "3") return wednesdayFields;
    if (dayNumber === "4") return thursdayFields;
    if (dayNumber === "5") return fridayFields;
    if (dayNumber === "6") return saturdayFields;
    if (dayNumber === "0") return sundayFields;
  };

  const appendSlot = (dayNumber: string, slot: Slot) => {
    if (dayNumber === "1") mondayAppend(slot);
    if (dayNumber === "2") tuesdayAppend(slot);
    if (dayNumber === "3") wednesdayAppend(slot);
    if (dayNumber === "4") thursdayAppend(slot);
    if (dayNumber === "5") fridayAppend(slot);
    if (dayNumber === "6") saturdayAppend(slot);
    if (dayNumber === "0") sundayAppend(slot);
  };

  const removeSlot = (dayNumber: string, index: number) => {
    if (dayNumber === "1") mondayRemove(index);
    if (dayNumber === "2") tuesdayRemove(index);
    if (dayNumber === "3") wednesdayRemove(index);
    if (dayNumber === "4") thursdayRemove(index);
    if (dayNumber === "5") fridayRemove(index);
    if (dayNumber === "6") saturdayRemove(index);
    if (dayNumber === "0") sundayRemove(index);
  };
  // we need to empty dates when hasSpecialDates become false
  useEffect(() => {
    if (!values.hasSpecialDates) {
      setValue("dates", undefined);
    }
  }, [values.hasSpecialDates]);

  const handleDeletePunctualSlot = (index: number) => {
    if (getValues(`slotPunctual.${index}.id`)) {
      setValue(`slotPunctual[${index}].flag` as any, SlotPunctual.DELETE);
    } else {
      slotPunctualRemove(index);
    }
  };

  return (
    <Container>
      <Locker
        isOpen={isLockerOpen}
        onClick={() => setIsLockerOpen(!isLockerOpen)}
      />
      <SecondaryTitleText>
        {t("hoursSubTitle").toUpperCase()}
      </SecondaryTitleText>
      {/* cause FormDiv have minWidth: 400px and it's too much for here */}
      <FormDiv style={{ minWidth: "200px" }} onSubmit={handleSubmit(onSubmit)}>
        <AlertMessage
          alertMessage={alertMessage.message}
          setAlertMessage={setAlertMessage}
          type={alertMessage.type}
          t={t}
        />
        <TemporaryActivityDiv>
          <Checkbox
            id={"hasSpecialDates"}
            name={"hasSpecialDates"}
            control={control}
            label={t("noPermanentActivity")}
            disabled={!isLockerOpen}
            t={t}
          />
          {hasSpecialDates ? (
            <>
              <InputText
                id={`dates.from`}
                name={`dates.from`}
                inputType="date"
                required
                disabled={!isLockerOpen}
                control={control}
                error={errors.dates ? errors.dates.from?.message : null}
                t={t}
              />
              <InputText
                id={`dates.to`}
                name={`dates.to`}
                inputType="date"
                required
                disabled={!isLockerOpen}
                control={control}
                error={errors.dates ? errors.dates.to?.message : null}
                t={t}
              />
            </>
          ) : null}
          <Row alignCenter gap="8px">
            <FormLabel style={{ minWidth: "unset" }}>
              {t("scheduleType")}
            </FormLabel>
            <HtmlTooltip title={<TooltipHour />}>
              <InfoIcon color="primary" />
            </HtmlTooltip>
          </Row>
          <Select
            id={"typeHours"}
            name={"typeHours"}
            label={t("scheduleType")}
            options={activityScheduleType}
            required
            disabled={!isLockerOpen}
            control={control}
            error={errors["typeHours"]?.message}
            t={t}
          />
          {values.typeHours === ActivityTypeHours.TIME_SLOTS && (
            <InputText
              id={"slotDuration"}
              name={"slotDuration"}
              label={t("slotDuration")}
              required
              disabled={!isLockerOpen}
              inputType="number"
              control={control}
              error={errors["slotDuration"]?.message}
              t={t}
              min={minimalTimeslotValue}
            />
          )}
        </TemporaryActivityDiv>
        <TabSize>
          <TabContext value={page}>
            <TabList
              onChange={(event, value) => setPage(value)}
              variant="scrollable"
              scrollButtons="auto"
            >
              {dayList.map((day, index) => (
                <Tab
                  label={daysNameMapping[day]}
                  key={index}
                  value={day}
                  style={{
                    borderBottom: 1,
                    backgroundColor: values.hours[day].slots.length
                      ? "white"
                      : "lightGrey",
                  }}
                />
              ))}
            </TabList>
            {dayList.map((day, index) => {
              const errorsOnDay = errors.hours
                ? errors.hours[day]?.slots
                : null;
              return (
                <TabPanel
                  key={index}
                  value={day}
                  style={{
                    margin: 0,
                    padding: 0,
                    width: "100%",
                    maxHeight: "250px",
                    overflowY: "scroll",
                    borderBottom: "solid 1px #f2f2f2",
                    paddingTop: "10px",
                  }}
                >
                  <div
                    style={{
                      padding: 0,
                      margin: 0,
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "flex-start",
                      gap: "20px",
                    }}
                  >
                    {slotIterator(day)?.map((slot: Slot, index: number) => (
                      <div
                        key={`hours.${day}.slots[${index}]`}
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "20px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            borderBottom: "solid lightGrey 1px",
                          }}
                        >
                          <InputText
                            id={`hours.${day}.slots[${index}].from`}
                            name={`hours.${day}.slots[${index}].from`}
                            label={t("start")}
                            inputType="time"
                            required
                            disabled={!isLockerOpen}
                            control={control}
                            error={
                              errorsOnDay && errorsOnDay[index]
                                ? errorsOnDay[index]?.from?.message
                                : null
                            }
                            t={t}
                          />
                          <InputText
                            id={`hours.${day}.slots[${index}].to`}
                            name={`hours.${day}.slots[${index}].to`}
                            label={t("end")}
                            inputType="time"
                            required
                            disabled={!isLockerOpen}
                            control={control}
                            error={
                              errorsOnDay && errorsOnDay[index]
                                ? errorsOnDay[index]?.to?.message
                                : null
                            }
                            t={t}
                          />
                        </div>
                        <DeleteIcon
                          style={{
                            color: isLockerOpen ? "#4FD1C5" : "#D3D3D3",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            isLockerOpen
                              ? removeSlot(day, index)
                              : console.log("locker closed")
                          }
                        />
                      </div>
                    ))}
                    <AddCircleOutlineIcon
                      style={{
                        color: isLockerOpen ? "#4FD1C5" : "#D3D3D3",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        isLockerOpen &&
                        isDayEnabled(enabledDaysForDuplication, day)
                          ? appendSlot(day, { from: "00:00", to: "00:00" })
                          : console.log("locker closed")
                      }
                    />
                  </div>
                </TabPanel>
              );
            })}
          </TabContext>
        </TabSize>

        {(offerOpeningHoursDuplication || showDuplicationDaysSelector) && (
          <div className="duplicate-hours-container">
            <span
              className="duplicate-hours"
              onClick={() => {
                setShowDuplicationDaysSelector(true);
              }}
            >
              {isTemporayActivityDatesRangeValid &&
                hasSpecialDates &&
                t("duplicateTimeslotsToDaysInRange")}
              {!hasSpecialDates && t("duplicateTimeslotsToWeek")}
            </span>
            {showDuplicationDaysSelector && (
              <InputCheckboxSelect
                id="days-selector"
                name="days-selector"
                multiple
                options={getSelectorDaysOptions(
                  daysNameMapping,
                  enabledDaysForDuplication,
                )}
                values={selectedDaysForDuplication}
                onChange={(event: any) =>
                  handleSelectChange(event, setSelectedDaysForDuplication)
                }
                placeholder={
                  isTemporayActivityDatesRangeValid
                    ? t("daysSelectorPlaceholderTemporayActivity")
                    : t("daysSelectorPlaceholder")
                }
                required={false}
                error={false}
                actionBtnLabel={t("duplicateActionBtn")}
                actionBtnFct={saveDuplicatedOpeningHours}
                actionBtnStyle={duplicateOpeningHoursBtnStyle}
              />
            )}
          </div>
        )}
        <Row width="100%">
          <Column>
            <FormLabel>{t("Ouvrir des dates non récurrentes")}</FormLabel>
            {slotPunctualFields.map((item, index) => {
              const hasId = Boolean(getValues(`slotPunctual.${index}.id`));
              return (
                <Row
                  key={item.id}
                  alignCenter
                  display={
                    getValues(`slotPunctual.${index}.flag`) ===
                    SlotPunctual.DELETE
                      ? "none"
                      : "flex"
                  }
                >
                  <input
                    type="hidden"
                    {...register(`slotPunctual.${index}.id`)}
                  />
                  <InputText
                    id={`slotPunctual.${index}.date`}
                    name={`slotPunctual.${index}.date`}
                    inputType="date"
                    required
                    disabled={!isLockerOpen || hasId}
                    control={control}
                    error={
                      errors.slotPunctual?.[index]?.date?.message
                        ? errors.slotPunctual?.[index]?.date?.message
                        : null
                    }
                    onChange={() => {
                      if (getValues(`slotPunctual.${index}.id`)) {
                        setValue(
                          `slotPunctual[${index}].flag` as any,
                          SlotPunctual.EDIT,
                        );
                      }
                    }}
                    t={t}
                  />
                  <InputText
                    id={`slotPunctual[${index}].slotFrom`}
                    name={`slotPunctual[${index}].slotFrom`}
                    label={t("start")}
                    inputType="time"
                    required
                    disabled={!isLockerOpen || hasId}
                    control={control}
                    error={
                      errors.slotPunctual?.[index]?.slotFrom?.message
                        ? errors.slotPunctual?.[index]?.slotFrom?.message
                        : null
                    }
                    onChange={() => {
                      if (getValues(`slotPunctual.${index}.id`)) {
                        setValue(
                          `slotPunctual[${index}].flag` as any,
                          SlotPunctual.EDIT,
                        );
                      }
                    }}
                    t={t}
                  />
                  <InputText
                    id={`slotPunctual[${index}].slotTo`}
                    name={`slotPunctual[${index}].slotTo`}
                    label={t("end")}
                    inputType="time"
                    required
                    disabled={!isLockerOpen || hasId}
                    control={control}
                    error={
                      errors.slotPunctual?.[index]?.slotTo?.message
                        ? errors.slotPunctual?.[index]?.slotTo?.message
                        : null
                    }
                    onChange={() => {
                      if (getValues(`slotPunctual.${index}.id`)) {
                        setValue(
                          `slotPunctual[${index}].flag` as any,
                          SlotPunctual.EDIT,
                        );
                      }
                    }}
                    t={t}
                  />
                  <DeleteIcon
                    style={{
                      color: isLockerOpen ? "#4FD1C5" : "#D3D3D3",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setIsOpenModalPunctual({
                        open: MODAL_WARNING_PUNCTUAL,
                        dataOverlap: {
                          index,
                        },
                      });
                    }}
                  />
                </Row>
              );
            })}
            <AddCircleOutlineIcon
              style={{
                color: isLockerOpen ? "#4FD1C5" : "#D3D3D3",
                cursor: "pointer",
              }}
              onClick={() =>
                isLockerOpen
                  ? slotPunctualAppend({
                      id: null,
                      date: "",
                      slotFrom: "00:00",
                      slotTo: "00:00",
                      flag: SlotPunctual.ADD,
                    })
                  : {}
              }
            />
          </Column>
        </Row>
        <Button
          disabled={!isLockerOpen || mutateActivity.isLoading}
          color="info"
          variant="contained"
          type="submit"
        >
          {t("save")}
        </Button>
      </FormDiv>
      <DialogConfirmation
        open={isOpenModal}
        setOpen={setIsOpenModal}
        title={t("saveDataHourTitle")}
        description={t("saveDataHourDescription", {
          day: arrayToFormattedString(
            errorValidation.errorsEmptySlot,
            t("and"),
          ),
        })}
        cancelTextButton={t("saveDataHourCancel")}
        submitTextButton={t("saveDataHourSubmit")}
        handleSubmit={() => {
          if (watch("slotPunctual")?.length && activityId) {
            mutateCheckOverlapping.mutate({
              activityId,
              data: { hours: payload() },
            });
          } else {
            mutateActivity.mutate(payload());
          }
        }}
      />
      <DialogConfirmation
        open={isTimeslotInfoModalOpen}
        setOpen={setIsTimeslotInfoModalOpen}
        title={t("saveDataHourTitle")}
        description={t("informationImportant", { slot: values.slotDuration })}
        handleSubmit={handleSubmit(onSubmit)}
      />
      <DialogPunctualSlot
        activityId={activityId!}
        open={isOpenModalPunctual.open === MODAL_CHECK_OVERLAPPING}
        setOpen={() =>
          setIsOpenModalPunctual((prev) => ({ ...prev, open: "" }))
        }
        dataOverlap={isOpenModalPunctual.dataOverlap}
        saveActivity={() => mutateActivity.mutate(payload())}
      />
      <DialogConfirmation
        title={t("attention")}
        description={t("deletePunctualWarningContent")}
        open={isOpenModalPunctual.open === MODAL_WARNING_PUNCTUAL}
        setOpen={() =>
          setIsOpenModalPunctual((prev) => ({ ...prev, open: "" }))
        }
        cancelTextButton={t("no")}
        submitTextButton={t("yes")}
        handleSubmit={() =>
          handleDeletePunctualSlot(
            (isOpenModalPunctual.dataOverlap as any).index,
          )
        }
      />
    </Container>
  );
}

const TemporaryActivityDiv = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
`;

const TabSize = styled.div`
  max-width: 550px;
  @media (max-width: 1500px) {
    max-width: 350px;
  }
  @media (max-width: 900px) {
    max-width: 250px;
  }
`;
