import { useTranslation } from "react-i18next";
import { MenuItem, Select } from "@mui/material";
import { useEffect } from "react";
import moment from "moment";
import "moment/locale/fr";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "store";
import { toggleLanguage } from "reducers/toggleReducer";
import ENFlag from "images/eng_flag.png";
import FRFlag from "images/fr_flag.png";

export default function LanguageSelectorWithFlag() {
  const { i18n } = useTranslation("auth");
  const dispatch = useDispatch()
  const { language } = useSelector((state: RootState) => state.toggle);

  useEffect(() => {
    i18n.changeLanguage(language);
    moment.locale(language);
  }, [i18n, language]);

  return (
    <Select
      value={language}
      onChange={(e) => dispatch(toggleLanguage(e.target.value as "en" | "fr"))}
      label="language"
      style={{
        width: "100%",
        color: "white",
        border: "#054643",
        outline: "#054643",
      }}
    >
      <MenuItem value={"fr"} style={{ display: "flex", alignItems: "center" }}>
        <img
          src={FRFlag}
          style={{ width: "25px", marginRight: "3px" }}
        />{" "}
        Français (FR)
      </MenuItem>
      <MenuItem value={"en"} style={{ display: "flex", alignItems: "center" }}>
        <img
          src={ENFlag}
          style={{ width: "25px", marginRight: "3px" }}
        />{" "}
        English (EN)
      </MenuItem>
    </Select>
  );
}